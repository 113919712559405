import styled from "styled-components"

import { SvgIconProps, Tooltip } from "@material-ui/core"

import { TChargeStatus } from "src/data/devices/types/deviceTypes"
import {
  TLangKeys,
  TTranslateFunction,
  useTranslate,
} from "src/i18n/useTranslate"
import { BatteryIcon } from "src/ui/BatteryIcon/BatteryIcon"
import ChargingBoltSvg from "src/ui/icons/charging-bolt.svg"
import ChargingPlugSvg from "src/ui/icons/charging-plug.svg"

export function BatteryAndChargeStatusIcon({
  percent,
  offline,
  chargeStatus,
  title,
  ...props
}: {
  percent: number | undefined
  offline: boolean | undefined
  chargeStatus?: TChargeStatus
  title?: React.ReactNode
} & SvgIconProps) {
  const { t, langKeys } = useTranslate()

  return (
    <Tooltip title={title || getLabel(t, langKeys, chargeStatus)}>
      <Box>
        <BatteryIcon percent={percent} offline={offline} {...props} />

        {chargeStatus === "connected_charging" && (
          <ChargingBoltIcon
            height={14}
            style={{ display: isCharging(chargeStatus) ? "block" : "none" }}
          />
        )}

        {chargeStatus === "connected_charged" && (
          <ChargingPlugIcon
            height={14}
            style={{ display: isCharging(chargeStatus) ? "block" : "none" }}
          />
        )}
      </Box>
    </Tooltip>
  )
}

function isCharging(chargeStatus?: TChargeStatus) {
  if (!chargeStatus) {
    return false
  }

  return ["connected_charging", "connected_charged"].includes(chargeStatus)
}

function isFullyCharged(chargeStatus?: TChargeStatus) {
  return chargeStatus === "connected_charged"
}

function getLabel(
  t: TTranslateFunction,
  langKeys: TLangKeys,
  chargeStatus?: TChargeStatus
) {
  if (!isCharging(chargeStatus)) {
    return ""
  }

  if (isFullyCharged(chargeStatus)) {
    return t(langKeys.sensor_fully_charged)
  }

  return t(langKeys.sensor_charging)
}

const Box = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

const ChargingBoltIcon = styled(ChargingBoltSvg)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-55%, -50%);
`

const ChargingPlugIcon = styled(ChargingPlugSvg)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-55%, -50%);
`
