import { SvgIconProps, Tooltip } from "@material-ui/core"

import { TSignalQuality } from "src/data/devices/types/deviceTypes"
import { ITranslateFunction } from "src/i18n/i18nTypes"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import WifiExcellent from "src/ui/icons/wifi-excellent.svg"
import WifiGood from "src/ui/icons/wifi-good.svg"
import WifiNone from "src/ui/icons/wifi-none.svg"
import WifiOkay from "src/ui/icons/wifi-okay.svg"
import WifiWeak from "src/ui/icons/wifi-weak.svg"

type SVGElement = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & { title?: string | undefined }
>

export function WifiIcon({
  quality,
  offline = false,
  title,
  ...props
}: SvgIconProps & {
  quality: TSignalQuality
  offline: boolean
  title?: React.ReactNode
}) {
  const { t } = useTranslate()

  const wifi = offline
    ? { title: t(langKeys.offline), svg: WifiNone }
    : getIconMapping({ quality, t })

  const WifiIcon = wifi.svg

  return (
    <Tooltip title={title || wifi.title}>
      <div>
        <WifiIcon height={30} width={30} {...props} />
      </div>
    </Tooltip>
  )
}

function getIconMapping({
  quality,
  t,
}: {
  quality: TSignalQuality
  t: ITranslateFunction
}) {
  const iconMapping: { svg: SVGElement; title: string }[] = [
    {
      title: t(langKeys.signal_weak),
      svg: WifiWeak,
    },
    {
      title: t(langKeys.signal_okay),
      svg: WifiOkay,
    },
    {
      title: t(langKeys.signal_fair),
      svg: WifiGood,
    },
    {
      title: t(langKeys.signal_good),
      svg: WifiGood,
    },
    {
      title: t(langKeys.signal_excellent),
      svg: WifiExcellent,
    },
  ]

  return iconMapping[Number(quality) || 0]
}
